import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Content from '../components/Service/Content'
import Hero from '../components/Service/Hero'
import Layout from '../components/Layout'
import FeatureCard from '../components/Card/Feature'
import CTA from '../components/Service/CTA'
import Products from '../components/Service/Products'
import SEO from '../components/SEO'

class ServicesItemTemplate extends React.Component {

  render() {
    const { data, location } = this.props;
    const page = data.allPrismicServicesItems.edges[0].node.data
    const mobileFeature = data.mobileFeature.edges[0].node.data

    return (
      <Layout location={location}>
        <SEO
          title={page.title || page.meta_title}
          type="article"
          description={page.lead || page.meta_description}
          location={location}
        />
        <div className="wrapper wrapper--y">

          {page.title && (
            <div className="mb-8 lg:mb-6">
              <Link className="inline-block transition-opacity hover:opacity-75 focus:opacity-75" to={page.category.document.uid}>
                <h4 className="heading-xxs mb-2">{page.category.document.data.heading}</h4>
              </Link>
              <h1 className="heading-2xl">
                {page.title}
              </h1>
            </div>
          )}

          <Hero
            lead={page.lead}
            body={page.body}
            listHeading={page.list_heading}
            list={page.list}
          />

          <Content content={page.content} />

          {page.featured_case_study.document !== null && (
            <FeatureCard
              data={page}
              mobileFeature={mobileFeature}
              position="bottom"
              inset={true}
            />
          )}

        </div>

        <CTA offset={page.featured_case_study.document !== null} />

        <div className="wrapper pb-4 lg:pb-12 pt-24">
          <div className="w-full lg:w-5/12">
            <h3 className="heading-xl">Brands we use</h3>
          </div>
        </div>
        <Products data={page} />

      </Layout>
    )
  }
}

ServicesItemTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export const ServicesItemQuery = graphql`
  query ServiceItems($uid: String!) {
    allPrismicServicesItems(
      filter: { uid: { eq: $uid } }
    ) {
      edges {
        node {
          data {
            title
            meta_title
            meta_description
            body {
              html
            }
            lead
            list_heading
            list {
              list_item
            }
            content {
              content_heading
              content_body {
                html
              }
            }
            category {
              document {
                ... on PrismicServices {
                  uid
                  id
                  data {
                    heading
                  }
                }
              }
            }
            items {
              product_download {
                url
              }
              product_heading
              product_image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 864, maxHeight: 703) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            icon {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 864, maxHeight: 703) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            featured_case_study {
              uid
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    heading
                    hero {
                      alt
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1317, maxHeight: 570) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            featured_case_study_text {
              text
              html
            }
          }
        }
      }
    }
    mobileFeature: allPrismicServicesItems(
      filter: { uid: { eq: $uid } }
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          data {
            featured_case_study {
              document {
                ... on PrismicCaseStudiesArticle {
                  data {
                    hero {
                      alt
                      copyright
                      url
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 640, maxHeight: 680) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ServicesItemTemplate
